import { Icons } from "../Icons/Icons";
import React, { useState } from "react";
import { Link } from "react-router-dom";

import "./Footer.scss";
export const Footer = () => {
  return (
    <section>
      <div className="wrapper">
        <div className="footer">
          <Icons />
        </div>
        <div className="footer__info">
          <div>
            <p>&#169; Марафон #місяцьчитання 2022-2025</p>
          </div>
          <div>
            <p>
              &#169;
              <a href="https://www.freepik.com">
                illustration vector created by freepik
              </a>
            </p>
          </div>
          <Link to="/privacy" className="privacy">
            ПОЛІТИКА КОНФІДЕНЦІЙНОСТІ
          </Link>
        </div>
      </div>
    </section>
  );
};
